.story-page {
    padding: 1.5rem;
    background-color: #faf2ef;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

.story-content {
    margin-top: 1rem;
    color: #333;
    font-size: 1em;
    line-height: 1.5;
}

.story-page h1 {
    margin-bottom: 10px;
    font-size: 1.6em;
    color: #333;
    transition: all 0.3s ease;
}

.story-page h2 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
    transition: all 0.3s ease;
    text-align: center;
}

.fullText {
    font-size: 1em;
    line-height: 1.5;
    text-align: left;
}

.poem {
    font-style: italic;
    text-align: right;
    margin: 1rem 0;
    display: block;
}

.story-page p {
    font-size: 1em;
    line-height: 1.5;
    text-align: left;
    word-wrap: break-word;
}
.bibliography {
    padding-top: 3rem;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .story-page {
        padding: 1rem;
    }

    .story-page h1 {
        font-size: 1.4em;
    }

    .story-page h2 {
        font-size: 1.1em;
    }

    .fullText, .poem .bibliography{
        font-size: 0.9em;
    }
}
/* Login Page Styles */
.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height for centering */
    background-color: #faf2ef;
    font-family: 'Courier New', Courier, monospace;
}

/* Login Form Styles */
.form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Limit form width */
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: #faf2ef;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #444;
}

/* Status Message Styles */
.status-message {
    font-size: 0.8em;
    color: #333;
    margin-top: 10px;
}

/* Media Queries for Mobile Devices */
@media screen and (max-width: 768px) {
    .login-page {
        padding: 10px; /* Add padding for smaller screens */
    }

    .form {
        width: 100%; /* Full width on mobile */
    }

    .form-input {
        margin-bottom: 10px; /* Adjust margin for mobile */
    }

    .submit-button {
        padding: 8px; /* Adjust padding for mobile */
    }
}

/* General layout */
.homepage {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: 'Courier New', Courier, monospace;
    background-color: #faf2ef;
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;
}

.stories-container {
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 8px 0;
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    text-align: center;
}

.section:hover {
    transform: scale(1.02);
}

.section:hover h2 {
    color: #333;
    transform: scale(1.05);
}

/* Horizontal divider styling */
.horizontal-divider {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #333;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.divider-text {
    position: absolute;
    background-color: #faf2ef;
    padding: 0 10px;
    font-size: 0.9em;
    color: #333;
    transition: all 0.3s ease;
}

.section:hover .divider-text {
    font-weight: bold;
    color: #333;
}

.section:hover .horizontal-divider {
    height: 2.0px;
}

/* Content styling */
h2 {
    margin-bottom: 10px;
    font-size: 1.2em;
    transition: all 0.3s ease;
    text-align: center;
    color:#333
}

p {
    font-size: 1em;
    line-height: 1.5;
}

/* Add media query for mobile devices */
@media screen and (max-width: 768px) {
    .stories-container {
        width: 90%;
        padding: 1rem;
    }

    .section {
        padding: 6px 0;
    }

    h2 {
        font-size: 1.1em;
    }

    p {
        font-size: 0.9em;
    }
}

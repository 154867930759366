.footer {
    width: 100%;
    background-color: #faf2ef;
    border-top: 2px solid #333;
    padding: 15px 0;
    font-family: 'Courier New', Courier, monospace;
    flex-shrink: 0;
    margin-top: auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
}

.footer-left {
    flex: 1;
}

.footer-logo {
    height: 40px;
    width: auto;
}

.footer-center {
    flex: 2;
    display: flex;
    justify-content: center;
    position: relative;
}

.message-box {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 600px;
}

.message-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #333;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    background-color: #faf2ef;
}

.status-message {
    position: static;
    font-size: 0.8em;
    color: #333;
    margin-top: 5px;
    margin-left: 5px;
}

.submit-button {
    padding: 8px 16px;
    background-color: #333;
    color: #faf2ef;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #444;
}

.footer-right {
    flex: 1;
    text-align: right;
}

.copyright {
    color: #4b4a47;
    font-size: 0.8em;
}

/* Add media query for mobile devices */
@media screen and (max-width: 768px) {
    .footer {
        padding: 10px 0;
    }

    .footer-content {
        flex-direction: column;
        gap: 5px;
    }

    .footer-left {
        display: none;
    }

    .footer-center {
        width: 100%;
    }

    .message-box {
        flex-direction: column;
        gap: 8px;
    }

    .message-input {
        width: 100%;
    }

    .submit-button {
        width: 100%;
    }

    .footer-right {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .status-message {
        position: static;
        transform: none;
        text-align: center;
        margin-top: 8px;
    }

    .copyright {
        font-size: 0.7em;
    }
}


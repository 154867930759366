* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}

.App {
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #faf2ef;
}

.story-page {
    padding: 1.5rem;
    background-color: #faf2ef;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Courier New', Courier, monospace;
    text-align: left;
}


  

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    background-color: #faf2ef;
    border-bottom: 2px solid #333;
    position: relative;
    flex-shrink: 0;
    user-select: none;
}

.logo {
    height: 90px;
    width: auto;
    cursor: pointer;
    user-select: none;
}

.logo-container {
    position: relative;
}

.clickable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    cursor: pointer;
}
